<template>
  <div class="card allWidgets">
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
      <div class="text">{{ t('loading.showcases') }}</div>
    </div>

    <div v-else>
      <div v-if="hasError">
        <h3>{{ t('showcasesNotLoaded') }}</h3>
        <p>{{ t('tryAgain') }}</p>
      </div>
    </div>

    <table v-if="!isLoading && !hasError">
      <thead>
      <th></th>
      <th>{{ t('name') }}</th>
      <th>{{ t('type') }}</th>
      </thead>
      <tr
        v-for="(item, index) in showcaseItems"
        :key="index"
        :class="{ deactive: !item.is_active }"
      >
        <td>
          <div class="images">
            <div
              v-for="(imageUrl, childIndex) in item.image_urls"
              :key="childIndex"
            >
              <img :src="imageUrl"/>
            </div>
          </div>
        </td>
        <td>{{ item.title }}</td>

        <td>{{ item.type }}</td>
        <td style="display: flex; column-gap: 0.4em; justify-content: flex-end">
          <router-link
            :to="{ name: 'HomePage.Edit.Page', params: { id: item.id } }"
            :title="t('edit')"
            class="btn btn-outline-primary"
          >
            <i class="pi pi-pencil"></i>
          </router-link>

          <a
            @click="toggleVisible(item.id)"
            :title="item.is_active ? 'doPassive' : 'doActive'"
            :class="{
              'btn-active': item.is_active,
              'btn-info': !item.is_active,
            }"
            class="btn"
          >
            <i class="pi pi-flag"></i>
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import useGlobal from '../../composables/useGlobal'
import { onMounted, ref } from 'vue'

export default {
  setup() {
    const { t, restApi, emitter } = useGlobal()

    const isLoading = ref(false)
    const hasError = ref(false)

    const showcaseItems = ref([])

    const toggleVisible = (id) => {
      restApi.restShowcaseToggleVisible(id).then(() => {
        emitter.emit('refresh-simulation')
        fetchList('not-loaded')
      })
    }

    const fetchList = (reload) => {
      if (reload === 'not-loaded') {
        isLoading.value = false
      } else {
        isLoading.value = true
      }
      restApi.fetchShowcasesItems().then((response) => {
        isLoading.value = false

        showcaseItems.value = response.items
      }).catch((error) => {
        isLoading.value = false
        hasError.value = true
      })
    }

    onMounted(() => {
      fetchList()
    })

    return {
      t,
      toggleVisible,

      showcaseItems,
      isLoading,
      hasError,
    }
  },
}
</script>

<style scoped>
.card {
  padding: 0.6em;
}

.allWidgets i {
  font-size: 0.8em;
  padding: 1px;
}

td,
th {
  padding: 0.5em;
}

tr:nth-child(odd) {
  background: #f2f2f287;
}

tr:hover {
  background: #f4f4f4;
}

.moveBtnSet {
  display: flex;
  width: max-content;
  gap: 0.3em;
  justify-content: space-between;
}

.images {
  display: flex;
  position: relative;
  gap: 4px;
  width: 220px;
  height: 80px;
  overflow: hidden;
  border-radius: 1em;
  border: 1px solid lightgrey;
}

.images img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.deactive {
  filter: grayscale(100%);
}

.btn-active {
  background: #222222;
  color: #fff;
}

/*response mobile*/
@media (max-width: 576px) {
  th {
    display: none;
  }

  td {
    display: block;
  }

  tr {
    margin-bottom: 2em;
    border-bottom: 2px solid lightgray;
    background-color: #fff !important;
  }
}
</style>
